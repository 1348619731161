<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">实名和身份认证</div>
      <div class="topRight"></div>
    </div>
    <div class="contentBox" v-if="status === 1">
      <div class="nameBox">
        <div><span>*</span>姓名</div>
        <div>
          <input type="text" placeholder="请输入姓名" v-model="authData.name" />
        </div>
      </div>
      <div class="nameBox">
        <div><span>*</span>身份证号</div>
        <div>
          <input
            type="text"
            placeholder="请输入身份证号"
            v-model="authData.idnumber"
          />
        </div>
      </div>
      <div class="schoolBox">
        <div><span>*</span>学校</div>
        <div @click="economize = true">
          <input type="text" v-model="economizeVlue" placeholder="请选择学校" />
          <img src="../../image/quanz/xl.png" alt="" />
        </div>
      </div>
      <van-popup v-model="economize" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          value-key="name"
          @confirm="onConfirm"
          @cancel="economize = false"
        />
      </van-popup>
      <div class="nameBox">
        <div><span>*</span>职位</div>
        <div>
          <input
            type="text"
            placeholder="请输入职位"
            v-model="authData.circleDuties"
          />
        </div>
      </div>
      <div class="nameBox">
        <div>邀请码</div>
        <div>
          <input
            type="text"
            placeholder="请输入邀请码"
            v-model="authData.inviteCode"
          />
        </div>
      </div>
      <div class="footerBtn" @click="submit()">确定</div>
    </div>
    <div class="rzBox" v-else>
      <img src="../../image/quanz/rzz.png" alt="" />
      <div>认证中</div>
      <div>您已提交审核，请稍后~</div>
    </div>
  </div>
</template>

<script>
import { circleAuth, schoolPage } from "../../api/circle";
import { Toast } from "vant";
export default {
  data() {
    return {
      economize: false,
      economizeVlue: "",
      columns: [],
      status: 1,
      authData: {
        name: "", //名字
        schoolId: "", //学校
        idnumber: "", //身份证号
        circleDuties: "", //职位
        inviteCode: "", //邀请码
      },
    };
  },
  mounted() {
    schoolPage().then((res) => {
      console.log(res, "学校");
      this.columns = res.data.data.items;
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onConfirm(value) {
      console.log(value);
      this.economizeVlue = value.name;
      this.authData.schoolId = value.id;
      this.economize = false;
    },
    submit() {
      console.log(this.authData);
      circleAuth(this.authData).then((res) => {
        console.log(res, "认证中");
        if (res.data.code === 0) {
          this.status = 2;
        } else {
          Toast(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.boxTop {
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 22px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  width: 48px;
  height: 48px;
  margin-right: 32px;
}
.nameBox {
  margin-left: 32px;
  margin-top: 32px;
}
.nameBox div:nth-child(1),
.schoolBox div:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: #455669;
}
.nameBox div:nth-child(1) span,
.schoolBox div:nth-child(1) span {
  color: #fa2819;
}
.nameBox input {
  width: 686px;
  height: 92px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: none;
  font-size: 32px;
  margin-top: 20px;
  padding-left: 24px;
  box-sizing: border-box;
}
.schoolBox {
  margin-left: 32px;
  margin-top: 32px;
}
.schoolBox div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 686px;
  height: 92px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-top: 20px;
}
.schoolBox input {
  width: 500px;
  height: 60px;
  border: none;
  font-size: 32px;
  font-weight: 400;
  color: #97a4b4;
  background: #f6f8fa;
  padding-left: 24px;
}
.schoolBox img {
  width: 36px;
  height: 36px;
  margin-right: 32px;
}
.footerBtn {
  position: fixed;
  bottom: 32px;
  left: 32px;
  width: 684px;
  height: 88px;
  background: #0957c3;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 88px;
}
.rzBox {
  text-align: center;
}
.rzBox img {
  width: 316px;
  height: 316px;
  margin-top: 300px;
}
.rzBox div:nth-child(2) {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.rzBox div:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: #b1b4c3;
  margin-top: 12px;
}
</style>
